import React, { useState } from "react";
import axios from "axios";

const ReviewForm = ({ audiobookId }) => {
  const [user, setUser] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios.post(
      `https://backend.prashantkr.online/audiobooks/${audiobookId}/reviews`,
      { user, rating, comment }
    );
    // Clear form and refresh data
  };

  return (
    <form className="review-form" onSubmit={handleSubmit}>
      <input
        value={user}
        onChange={(e) => setUser(e.target.value)}
        placeholder="User"
        required
      />
      <input
        value={rating}
        onChange={(e) => setRating(e.target.value)}
        placeholder="Rating"
        type="number"
        required
      />
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Write a short review..."
        required
      ></textarea>
      <button type="submit">Submit Review</button>
    </form>
  );
};

export default ReviewForm;
