import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AudiobookList from "./components/AudiobookList";
import AudiobookDetails from "./components/AudiobookDetails";
import Header from './components/Header'; // Import Header component

const App = () => {
  return (
    <Router>
      <Header /> {/* Include Header component */}
      <Routes>
        <Route path="/" element={<AudiobookList />} />
        <Route path="/audiobooks/:id" element={<AudiobookDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
