import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AudiobookList = () => {
  const [audiobooks, setAudiobooks] = useState([]);
  const [genre, setGenre] = useState("");
  const [author, setAuthor] = useState("");
  const [sort, setSort] = useState("");

  const fetchAudiobooks = useCallback(() => {
    axios
      .get("https://backend.prashantkr.online/audiobooks", {
        params: { genre, author, sort },
      })
      .then((response) => setAudiobooks(response.data))
      .catch((error) => console.error(error));
  }, [genre, author, sort]);

  useEffect(() => {
    fetchAudiobooks();
  }, [fetchAudiobooks]);

  return (
    <div className="container">
      <div className="filters">
        <select value={genre} onChange={(e) => setGenre(e.target.value)}>
          <option value="">All Genres</option>
          <option value="Fiction">Fiction</option>
          <option value="Science Fiction">Science Fiction</option>
          <option value="Fantasy">Fantasy</option>
          <option value="Mystery, Thriller">Mystery Thriller</option>
          <option value="Adventure">Adventure</option>
          <option value="Romance">Romance</option>
        </select>

        <input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Search by Author"
        />

        <select value={sort} onChange={(e) => setSort(e.target.value)}>
          <option value="">Sort By</option>
          <option value="title">Title</option>
          <option value="author">Author</option>
          <option value="rating">Average Rating</option>{" "}
          {/* Change label to Average Rating */}
        </select>
      </div>

      <div className="audiobook-list">
        {audiobooks.map((audiobook) => (
          <div className="audiobook-item" key={audiobook._id}>
            <Link to={`/audiobooks/${audiobook._id}`}>
              <img src={audiobook.coverImage} alt={audiobook.title} />
              <h3>{audiobook.title}</h3>
              <p>{audiobook.author}</p>
              <p className="rating">
                Average Rating: {audiobook.rating?.toFixed(1)}
              </p>{" "}
              {/* Display average rating */}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AudiobookList;
