import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReviewForm from "./ReviewForm";

const AudiobookDetails = () => {
  const { id } = useParams();
  const [audiobook, setAudiobook] = useState(null);

  useEffect(() => {
    axios
      .get(`https://backend.prashantkr.online/audiobooks/${id}`)
      .then((response) => setAudiobook(response.data))
      .catch((error) => console.error(error));
  }, [id]);

  if (!audiobook) return <div>Loading...</div>;

  return (
    <div className="container">
      <div className="audiobook-details">
        <img src={audiobook.coverImage} alt={audiobook.title} />
        <div>
          <h2>{audiobook.title}</h2>
          <p>{audiobook.author}</p>
          <p>{audiobook.description}</p>
          <p>Genre: {audiobook.genre}</p> {/* Display genre */}
          <p>Average Rating: {audiobook.averageRating.toFixed(1)}</p>{" "}
          {/* Display average rating */}
        </div>
        <ReviewForm audiobookId={audiobook._id} />
        <div className="reviews">
          {audiobook.reviews.map((review) => (
            <div className="review-item" key={review._id}>
              <p className="user">{review.user}</p>
              <p>Rating: {review.rating}</p>
              <p>{review.comment}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AudiobookDetails;
